require.config({
    paths: {
        'sdk': uiBuilderSDKPath + '/sdk',
    }
});

define(['sdk'], BackendlessUI => BackendlessUI.startApp());

define('./functions/34bc5a9dad2e70c1ea2169452bd21508/code.js', () => { 

BackendlessUI.Functions.Custom['fn_34bc5a9dad2e70c1ea2169452bd21508'] = function fn_34bc5a9dad2e70c1ea2169452bd21508(month_num) {
return (['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'][((month_num - 1) - 1)])
}

})
define('./functions/d58aea1a4fdc960e8bfcc8c9972ad006/code.js', () => { 

BackendlessUI.Functions.Custom['fn_d58aea1a4fdc960e8bfcc8c9972ad006'] = function fn_d58aea1a4fdc960e8bfcc8c9972ad006(succes_url, cancel_url, item_list) {
function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



return ([String('success_url=') + String(succes_url),'&',String('cancel_url=') + String(cancel_url),'&',String('mode=') + String('payment'),'&',String('customer_creation=') + String('always'),'&',String('phone_number_collection[enabled]=') + String('true'),'&',String('billing_address_collection=') + String('required'),['&','line_items[0][price_data][currency]=','USD','&','line_items[0][price_data][product_data][name]=',(getObjectProperty((item_list[0]), 'name')),'&','line_items[0][price_data][unit_amount]=',(getObjectProperty((item_list[0]), 'price')),'&','line_items[0][quantity]=',(getObjectProperty((item_list[0]), 'quant'))].join(''),['&','line_items[1][price_data][currency]=','USD','&','line_items[1][price_data][product_data][name]=',(getObjectProperty((item_list[1]), 'name')),'&','line_items[1][price_data][unit_amount]=',(getObjectProperty((item_list[1]), 'price')),'&','line_items[1][quantity]=',(getObjectProperty((item_list[1]), 'quant'))].join(''),3 == item_list.length ? ['&','line_items[2][price_data][currency]=','USD','&','line_items[2][price_data][product_data][name]=',(getObjectProperty((item_list[2]), 'name')),'&','line_items[2][price_data][unit_amount]=',(getObjectProperty((item_list[2]), 'price')),'&','line_items[2][quantity]=',(getObjectProperty((item_list[2]), 'quant'))].join('') : ''].join(''))
}

})
define('./functions/efab0d1eefce8e68bee14fa523a21807/code.js', () => { 

BackendlessUI.Functions.Custom['fn_efab0d1eefce8e68bee14fa523a21807'] = function fn_efab0d1eefce8e68bee14fa523a21807(weekday_num) {
return (['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'][((weekday_num - 1) - 1)])
}

})
define('./pages/404/components/902c9641de4ec9e73427e7192a118d14/bundle.js', [], () => ({
  /* content */

  /* handler:onClick */
  async onClick(___arguments) {
      ;await ( async function (pageName, pageData){ BackendlessUI.goToPage(pageName, pageData) })('', null);

  },  
/* handler:onClick *//* content */
}));

define('./pages/cancel/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var site_registration, new_reg_end;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  site_registration = (await Backendless.Data.of('site_reg').find(Backendless.DataQueryBuilder.create().setWhereClause((['checkoutId=\'',(getObjectProperty(___arguments.context.pageData, 'session_id')),'\''].join(''))).setPageSize(10)))[0];
  site_registration['reg_end'] = (new Date());
  console.log((await Backendless.Data.of('site_reg').save(site_registration, true)));

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/cancel/components/b35c7931cbf7cf6ee386659fe02c8683/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://share.hsforms.com/1hqWbzdcgR8-hRtPNpP3cnAe8t8w', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

define('./pages/log-on/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var error;

async function socialLogin(providerCode, fieldsMappings, scope, options, redirectToPage, callbackUrlDomain) {
  if (typeof BackendlessUI !== 'undefined' && await BackendlessUI.DeviceAPI.isRunningEnv('NATIVE_SHELL')) {
    const user = await BackendlessUI.DeviceAPI.socialLogin(providerCode, fieldsMappings, scope, options, callbackUrlDomain)

    Backendless.UserService.setCurrentUser(user, true)

    if (redirectToPage) {
      BackendlessUI.Navigator.goToPage(redirectToPage)
    }
  } else {
    const redirectAfterLoginUrl = redirectToPage
      ? window.location.href.split('?')[0] + `?page=${ redirectToPage }`
      : window.location.href

    window.location = await Backendless.UserService.getAuthorizationUrlLink(providerCode, fieldsMappings, scope, false, redirectAfterLoginUrl, callbackUrlDomain)
  }}


  try {
    await socialLogin('auth0', null, null, 'connection=sms', 'https://quickskin.backendless.app/api/users/oauth/auth0/authorize', null);

  } catch (error) {
    console.log(error);

  }

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/options/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  ['onBeforeEnter'](___arguments) {
      ___arguments.context.pageData['permit_type'] = 'no-selection';
  ___arguments.context.pageData['i_agree'] = 'false';
  ___arguments.context.pageData['num_nights'] = 0;
  console.log(JSON.stringify(___arguments.context.pageData));

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/options/components/e93cc65f7c3152dd91b9118f7f580fad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var site_registration;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  if ((getObjectProperty(___arguments.context.pageData, 'permit_type')) == 'no-selection') {
    (function (message) { alert(message) })('You have to make a park pass selection.');
  } else if ((getObjectProperty(___arguments.context.pageData, 'i_agree')) == 'false') {
    (function (message) { alert(message) })('You have to agree to follow the rules.');
  } else if ((getObjectProperty(___arguments.context.pageData, 'num_nights')) == 0) {
    (function (message) { alert(message) })('Please use the slider to select at least one night.');
  } else {
    site_registration = (await Backendless.Data.of('site_reg').find(Backendless.DataQueryBuilder.create().setWhereClause((['objectId=\'',(getObjectProperty(___arguments.context.pageData, 'reg_id')),'\''].join(''))).setPageSize(10)))[0];
    console.log(site_registration);
    site_registration['num_nights'] = (getObjectProperty(___arguments.context.pageData, 'num_nights'));
    site_registration['i_agree'] = (getObjectProperty(___arguments.context.pageData, 'i_agree'));
    site_registration['permit_type'] = (getObjectProperty(___arguments.context.pageData, 'permit_type'));
    console.log((await Backendless.Data.of('site_reg').save(site_registration, true)));
    (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('payment', ({ [`num_nights`]: (getObjectProperty(___arguments.context.pageData, 'num_nights')),[`i_agree`]: (getObjectProperty(___arguments.context.pageData, 'i_agree')),[`permit_type`]: (getObjectProperty(___arguments.context.pageData, 'permit_type')),[`reg_id`]: (getObjectProperty(___arguments.context.pageData, 'reg_id')) }));
  }

  },
  /* handler:onClick */
  /* handler:onLabelAssignment */
  ['onLabelAssignment'](___arguments) {
    var permit_total, site_total;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  site_total = 20 * (getObjectProperty(___arguments.context.pageData, 'num_nights'));
  if ('daily_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 5 * (getObjectProperty(___arguments.context.pageData, 'num_nights'));
  } else if ('annual_non_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 25;
  } else if ('annual_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 15;
  } else if ('no_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    permit_total = 0;
  } else {
    permit_total = 0;
  }

  return (String('Set Your Site and Pay - $') + String(site_total + permit_total))

  },
  /* handler:onLabelAssignment */
  /* content */
}))

define('./pages/options/components/bca7bdc3dcef530b87e50dd5308389a8/bundle.js', [], () => ({
  /* content */
  /* handler:onChange */
  ['onChange'](___arguments) {
      ___arguments.context.pageData['i_agree'] = ___arguments.value;

  },
  /* handler:onChange */
  /* content */
}))

define('./pages/org_unit/components/d3caff137bd685ed36330bb395d80c4c/bundle.js', [], () => ({
  /* content */
  /* handler:onPointsAssignment */
  async ['onPointsAssignment'](___arguments) {
      console.log(await Backendless.Data.of('sites').find(Backendless.DataQueryBuilder.create().setPageSize(10)));

  },
  /* handler:onPointsAssignment */
  /* handler:onMapCenterAssignment */
  ['onMapCenterAssignment'](___arguments) {
      return ({ [`lat`]: '-96.6348142',[`lng`]: '40.8007402' })

  },
  /* handler:onMapCenterAssignment */
  /* content */
}))

define('./pages/payment/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var checkout_obj, error, list, service_objs, pass_included;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}

function addItemToList(l, v) { Array.prototype.push.apply(l, Array.isArray(v) ? v : [v]);return l;}


  console.log(JSON.stringify(___arguments.context.pageData));
  service_objs = ({ [`site`]: ({ [`servName`]: 'Wanahoo Electric Site',[`price`]: 2000 }),[`daily_permit`]: ({ [`servName`]: 'Lower Platte North NRD Daily Permit',[`price`]: 500 }),[`annual_res_permit`]: ({ [`servName`]: 'Lower Platte North NRD Annual Resident Permit',[`price`]: 1500 }),[`annual_non_res_permit`]: ({ [`servName`]: 'Lower Platte North NRD Annual Non-Resident Permit',[`price`]: 2500 }) });
  console.log(service_objs);
  pass_included = (getObjectProperty(___arguments.context.pageData, 'pass_type')) == 'nopass' ? false : true;
  console.log(pass_included);
  list = [];
  addItemToList(list, ({ [`name`]: (getObjectProperty(service_objs, 'site.servName')),[`price`]: (Number((getObjectProperty(service_objs, 'site.price')))),[`quant`]: (Number((getObjectProperty(___arguments.context.pageData, 'num_nights')))) }));
  console.log(getObjectProperty(___arguments.context.pageData, 'pass_type'));
  if ('daily_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    addItemToList(list, ({ [`name`]: (getObjectProperty(service_objs, 'daily_permit.servName')),[`price`]: (Number((getObjectProperty(service_objs, 'daily_permit.price')))),[`quant`]: (Number((getObjectProperty(___arguments.context.pageData, 'num_nights')))) }));
  } else if ('annual_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    addItemToList(list, ({ [`name`]: (getObjectProperty(service_objs, 'annual_res_permit.servName')),[`price`]: (Number((getObjectProperty(service_objs, 'annual_res_permit.price')))),[`quant`]: 1 }));
  } else if ('annual_non_res_permit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
    addItemToList(list, ({ [`name`]: (getObjectProperty(service_objs, 'annual_non_res_permit.servName')),[`price`]: (Number((getObjectProperty(service_objs, 'annual_non_res_permit.price')))),[`quant`]: 1 }));
  } else if ('nopermit' == (getObjectProperty(___arguments.context.pageData, 'permit_type'))) {
  }
  addItemToList(list, ({ [`name`]: 'Set Your Sites Fee',[`price`]: Math.min(Math.max(200 * (Number((getObjectProperty(___arguments.context.pageData, 'num_nights')))), 100), 800),[`quant`]: 1 }));
  console.log(list);
  console.log((await BackendlessUI.Functions.Custom['fn_d58aea1a4fdc960e8bfcc8c9972ad006']((encodeURIComponent('https://app.setyoursites.com/index.html?page=success&session_id={CHECKOUT_SESSION_ID}')), (encodeURIComponent('https://app.setyoursites.com/index.html?page=cancel&session_id={CHECKOUT_SESSION_ID}')), list)));
  checkout_obj = (await Backendless.Request['post']((function(url){ if( !url ) { throw new Error('Url must be specified.')} if( !url.startsWith('http://') && !url.startsWith('https://')) { return 'https://' + url } return url})('https://api.stripe.com/v1/checkout/sessions')).query(({  })).set(({ [`Authorization`]: 'Bearer sk_test_51Ob9dYKy6fhsjAbb98vBVDnZH1luGUA6HwRhifoGtI4UztLDL66F2HD5YyEdz2qCpwoR94nT2MSOuL2PBBk4qSt500uMSRkE8C',[`Content-Type`]: 'application/x-www-form-urlencoded' })).setEncoding('utf8').send((await BackendlessUI.Functions.Custom['fn_d58aea1a4fdc960e8bfcc8c9972ad006']((encodeURIComponent('https://app.setyoursites.com/index.html?page=success&session_id={CHECKOUT_SESSION_ID}')), (encodeURIComponent('https://app.setyoursites.com/index.html?page=cancel&session_id={CHECKOUT_SESSION_ID}')), list))));
  try {
    await Backendless.Request.post(`${Backendless.appPath}/services/regs/add_ckoid`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send({ 'reg_id': (getObjectProperty(___arguments.context.pageData, 'reg_id')),'ckoid': (getObjectProperty(checkout_obj, 'id')) });

  } catch (error) {
    console.log(error);

  }
  (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })((getObjectProperty(checkout_obj, 'url')), false, undefined);

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/site-status/components/e93cc65f7c3152dd91b9118f7f580fad/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  async ['onClick'](___arguments) {
    var registration, error;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  try {
    registration = (await Backendless.Request.post(`${Backendless.appPath}/services/regs/new`).set('Content-Type', 'application/json').set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).send(JSON.stringify((getObjectProperty(___arguments.context.pageData, 'siteObjectId')))));

  } catch (error) {
    console.log(error);

  }
  console.log(getObjectProperty(registration, 'objectId'));
  (function (pageName, pageData) { BackendlessUI.Navigator.goToPage(pageName, pageData) })('options', ({ [`reg_id`]: (getObjectProperty(registration, 'objectId')) }));

  },
  /* handler:onClick */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'site_avail'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/site-status/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var loaded_site;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  loaded_site = (await Backendless.Request.get(`${Backendless.appPath}/services/sites/check`).set((function(h){if(!h['user-token']){delete h['user-token']} return h})({...{}, ...{ 'user-token': Backendless.getCurrentUserToken() }})).query({ 'pid': JSON.stringify((getObjectProperty(___arguments.context.pageData, 'physical_id'))) }).send());
  ___arguments.context.pageData['site_name'] = (getObjectProperty(loaded_site, 'site.site.site_name'));
  ___arguments.context.pageData['site_avail'] = (getObjectProperty(loaded_site, 'site.available'));
  ___arguments.context.pageData['org_unit_name'] = (getObjectProperty(((getObjectProperty((getObjectProperty((getObjectProperty(loaded_site, 'site')), 'site')), 'org_unit'))[0]), 'org_unit_name'));
  ___arguments.context.pageData['siteObjectId'] = (getObjectProperty(loaded_site, 'site.site.objectId'));

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/site-status/components/b805671e4a1e37767e4841bcce228abf/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (!(getObjectProperty(___arguments.context.pageData, 'site_avail')))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/site-status/components/101133bd3c65dcc1199f854b5bf6716e/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ((getObjectProperty(___arguments.context.pageData, 'site_avail')) ? 'This site is available!' : 'This site is not currently available.')

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/site-status/components/401aeab94dd152d9becc08ec23f29858/bundle.js', [], () => ({
  /* content */
  /* handler:onDisplayAssignment */
  ['onDisplayAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (getObjectProperty(___arguments.context.pageData, 'site_avail'))

  },
  /* handler:onDisplayAssignment */
  /* content */
}))

define('./pages/site-status/components/1f941ae64af645ac3fe266c5d84ed395/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return ([(getObjectProperty(___arguments.context.pageData, 'org_unit_name')),' - ',(getObjectProperty(___arguments.context.pageData, 'site_name'))].join(''))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/success/components/page/bundle.js', [], () => ({
  /* content */
  /* handler:onBeforeEnter */
  async ['onBeforeEnter'](___arguments) {
    var site_registration, new_reg_end, reg_start;

function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}


  site_registration = (await Backendless.Data.of('site_reg').find(Backendless.DataQueryBuilder.create().setWhereClause((['checkoutId=\'',(getObjectProperty(___arguments.context.pageData, 'session_id')),'\''].join(''))).setPageSize(10)))[0];
  console.log(site_registration);
  reg_start = (JSON.parse((getObjectProperty(site_registration, 'reg_start'))));
  new_reg_end = (new Date((new Date(reg_start)).getTime() + (((Number((getObjectProperty(site_registration, 'num_nights')))) * 86400) * 1000)));
  console.log(reg_start);
  console.log(new_reg_end);
  new_reg_end.setHours(14);
  new_reg_end.setMinutes(0);
  new_reg_end.setSeconds(0);
  site_registration['reg_end'] = new_reg_end;
  ___arguments.context.pageData['site_reg'] = site_registration;
  console.log((await Backendless.Data.of('site_reg').save(site_registration, true)));

  },
  /* handler:onBeforeEnter */
  /* content */
}))

define('./pages/success/components/0465abf544ddda81122fd7ba4dca77f3/bundle.js', [], () => ({
  /* content */
  /* handler:onContentAssignment */
  ['onContentAssignment'](___arguments) {
    function getObjectProperty(object, propPath) {
  if (typeof propPath !== 'string' || object[propPath] !== undefined) {
    return object[propPath]
  }

  const propsNamesList = propPath.split('.')

  let result = object

  for (let i = 0; i < propsNamesList.length; i++) {
    if (!result || result[propsNamesList[i]] === undefined) {
      return
    }

    result = result[propsNamesList[i]]
  }

  return result
}



  return (String('Your site is set through ') + String(new Intl.DateTimeFormat('us', ({ [`dateStyle`]: 'full',[`timeStyle`]: 'medium',[`propName`]: 'true' })).format((getObjectProperty(___arguments.context.pageData, 'site_reg.reg_end')))))

  },
  /* handler:onContentAssignment */
  /* content */
}))

define('./pages/success/components/b35c7931cbf7cf6ee386659fe02c8683/bundle.js', [], () => ({
  /* content */
  /* handler:onClick */
  ['onClick'](___arguments) {
      (function (url, isExternal, params) { BackendlessUI.Navigator.openWebPage(url, isExternal, params); })('https://share.hsforms.com/1hqWbzdcgR8-hRtPNpP3cnAe8t8w', true, undefined);

  },
  /* handler:onClick */
  /* content */
}))

